import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { Box, IconButton, SvgIcon, Typography } from '@mui/material';

import {
  DataTableDownloadQuery,
  DevCenterDataTableDownloadQuery,
  useDataTableDownloadQuery
} from '../../__generated__/apollo-hooks';
import { GRAPHQL_POLLING_INTERVAL } from '../../constants/general';
import { ERROR_NO_DATA_TABLE_CONTENT } from '../../constants/messages';
import {
  DownloadDataTableManifest,
  DownloadsContext,
  DownloadState,
  ManifestKind,
  REMOVE_DOWNLOAD_DELAY
} from '../../contexts/downloads.context';
import { showNotification } from '../../helpers/notification.helper';
import { toBigInt } from '../../helpers/number.helper';
import { useAuthDownload } from '../../hooks/use-auth-download.hooks';
import { ModCancelIcon } from '../../icons/moderne-icons';
import { FlexBox } from '../styled-components/layouts/layouts.styled';
import { DownloadTimer } from './download-timer.component';
import { DownloadableRow } from './downloadable-row.styled';
import { DownloadStateIcon } from './downloadable.component';

const MIN_FILE_CONTENT_LENGTH = 18; // origin,path,branch

type DataTableDownloadableProps = {
  manifest: DownloadDataTableManifest;
};

type DataTableDownloadableDetails = {
  state:
    | DataTableDownloadQuery['dataTableDownload']['state']
    | DevCenterDataTableDownloadQuery['devCenterDataTableDownload']['state'];
  downloadUrl?: string;
  stats?: DataTableDownloadQuery['dataTableDownload']['stats'];
};

export const DataTableDownloadable: FunctionComponent<
  DataTableDownloadableProps
> = ({ manifest }) => {
  const { fileName, onDownloadEnd, id } = manifest;
  const { data, error, stopPolling } = useDataTableDownloadQuery({
    pollInterval: GRAPHQL_POLLING_INTERVAL,
    variables: { id }
  });

  const downloadDetails: DataTableDownloadableDetails | undefined =
    data?.dataTableDownload;

  const downloadUrl: string = downloadDetails?.downloadUrl;

  const [downloaded, setDownloaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [alerted, setAlerted] = useState(false);

  const { removeDownload, updateDownloadState } = useContext(DownloadsContext);

  const authDownload = useAuthDownload();

  useEffect(() => {
    if (downloaded) {
      setTimeout(() => {
        removeDownload(fileName);
      }, REMOVE_DOWNLOAD_DELAY);
    }
  }, [downloaded, fileName, removeDownload]);

  const showNotificationUnlessAlerted = useCallback(
    (title: string, message: string) => {
      if (!alerted) {
        showNotification(title, message);
        setAlerted(true);
      }
    },
    [alerted]
  );

  // map devcenter states to data table states
  let state = downloadDetails?.state;
  if (state === 'QUEUED') {
    state = 'PENDING';
  } else if (state === 'FAILED') {
    state = 'FAILURE';
  } else if (state === 'SUCCESSFUL') {
    state = 'SUCCESS';
  }

  const isRealError = error && !error.networkError;
  const isInFailedState =
    isRealError || errorMessage || (data && state === 'FAILURE');

  const currentState: DownloadState = isInFailedState ? 'FAILURE' : state;

  // Click the download button when download is ready
  useEffect(() => {
    if (currentState && manifest.state !== currentState) {
      updateDownloadState(manifest.indexDbId, currentState);
      return;
    }

    // early return if still loading
    if (
      currentState === undefined ||
      downloadUrl === undefined ||
      (manifest.kind === ManifestKind.DATA_TABLE &&
        downloadDetails?.stats?.fileSize === undefined)
    ) {
      return;
    }

    // terminal state
    if (currentState !== 'PENDING') {
      stopPolling();
      if (
        manifest.kind === ManifestKind.DATA_TABLE &&
        downloadDetails.stats?.fileSize &&
        toBigInt(downloadDetails.stats?.fileSize) <= MIN_FILE_CONTENT_LENGTH
      ) {
        showNotificationUnlessAlerted('Error downloading', `File: ${fileName}`);
        setErrorMessage(ERROR_NO_DATA_TABLE_CONTENT);
        return;
      }

      if (currentState === 'FAILURE') {
        showNotificationUnlessAlerted('Error downloading', `File: ${fileName}`);
      }

      if (!downloaded && currentState === 'SUCCESS') {
        authDownload({
          path: downloadUrl,
          fileName,
          errorMessage: 'Error downloading file'
        }).then(() => {
          showNotificationUnlessAlerted(
            'Download complete',
            `File: ${fileName}`
          );
          if (onDownloadEnd) {
            onDownloadEnd();
          }
          setDownloaded(true);
        });
      }
    }
  }, [
    authDownload,
    downloaded,
    fileName,
    onDownloadEnd,
    stopPolling,
    showNotificationUnlessAlerted,
    updateDownloadState,
    manifest.indexDbId,
    currentState,
    manifest.state,
    manifest.kind,
    downloadDetails?.stats?.fileSize,
    downloadUrl
  ]);

  const handleDismissDownload = () => removeDownload(fileName);

  return (
    <DownloadableRow>
      <Box>
        <Typography variant="bodySm">{fileName}</Typography>
        {state === 'PENDING' && (
          <DownloadTimer startTimestamp={manifest.startTimestamp} />
        )}
        {state === 'SUCCESS' && (
          <Typography variant="caption">Completed</Typography>
        )}
        {isInFailedState && (
          <Typography
            variant="subtitle2"
            sx={{
              marginRight: 1,
              color: 'error'
            }}>
            {errorMessage || 'Error'}
          </Typography>
        )}
      </Box>
      <FlexBox>
        <IconButton
          onClick={handleDismissDownload}
          size="small"
          disabled={state === 'SUCCESS'}>
          <SvgIcon
            component={ModCancelIcon}
            inheritViewBox
            color={state === 'SUCCESS' ? 'inherit' : 'secondary'}
            sx={{ fontSize: (theme) => theme.typography.pxToRem(32.5) }}
          />
        </IconButton>
        <IconButton size="small" sx={{ padding: 0 }}>
          <DownloadStateIcon state={currentState} />
        </IconButton>
      </FlexBox>
    </DownloadableRow>
  );
};
