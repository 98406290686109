import { FunctionComponent } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

import {
  RepositoryFragment,
  ScmConfiguration,
  isRepositoryAAzureDevOpsRepository,
  isRepositoryABitbucketCloudRepository,
  isRepositoryABitbucketRepository,
  isRepositoryAGitHubRepository,
  isRepositoryAGitLabRepository,
  isScmConfigurationAAzureDevOpsConfiguration,
  isScmConfigurationABitbucketCloudConfiguration,
  isScmConfigurationABitbucketConfiguration,
  isScmConfigurationAGitLabConfiguration,
  isScmConfigurationAGithubConfiguration
} from '../../../__generated__/apollo-hooks';
import { WorkerRepositoryFragment } from '../../../__generated__/apollo-hooks-worker';
import {
  AzureDevOpsIcon,
  BitBucketIcon,
  GitHubIcon,
  GitLabIcon
} from '../../../icons/icons';

export type PossibleScmConfiguration = Pick<
  ScmConfiguration | RepositoryFragment | WorkerRepositoryFragment,
  '__typename'
>;

type ScmIconProps = {
  configuration: PossibleScmConfiguration;
  SvgIconProps?: SvgIconProps;
};

export const ScmConfigurationIcon: FunctionComponent<ScmIconProps> = ({
  configuration,
  SvgIconProps = {}
}) => {
  const sx = SvgIconProps?.sx || {};
  switch (true) {
    case isScmConfigurationAGithubConfiguration(configuration):
    case isRepositoryAGitHubRepository(configuration):
      return <GitHubIcon color="black" inheritViewBox {...SvgIconProps} />;
    case isScmConfigurationABitbucketConfiguration(configuration):
    case isScmConfigurationABitbucketCloudConfiguration(configuration):
    case isRepositoryABitbucketRepository(configuration):
    case isRepositoryABitbucketCloudRepository(configuration):
      return <BitBucketIcon color="black" inheritViewBox {...SvgIconProps} />;
    case isScmConfigurationAGitLabConfiguration(configuration):
    case isRepositoryAGitLabRepository(configuration):
      return <GitLabIcon color="black" inheritViewBox {...SvgIconProps} />;
    case isScmConfigurationAAzureDevOpsConfiguration(configuration):
    case isRepositoryAAzureDevOpsRepository(configuration):
      return (
        <SvgIcon
          inheritViewBox
          {...SvgIconProps}
          sx={{ ...sx, color: 'black' }}>
          <AzureDevOpsIcon color="black" />
        </SvgIcon>
      );
    default:
      return null;
  }
};
