import { ReactNode } from 'react';

import {
  Alert,
  AlertColor,
  AlertProps,
  AlertTitle,
  Box,
  BoxProps,
  Stack,
  Typography
} from '@mui/material';

import { FunctionComponentWithChildren } from '@bonsai-components/utility-types';
import { CenteredBox } from '../../styled-components/layouts/layouts.styled';

export const EmptyState: FunctionComponentWithChildren<{
  variant: AlertColor;
  headline?: string | ReactNode;
  body?: string | ReactNode;
  action?: ReactNode;

  slotProps?: {
    box?: BoxProps;
    alert?: AlertProps;
  };
}> = ({
  headline = undefined,
  body = undefined,
  variant = 'info',
  action = undefined,
  slotProps = {},
  children
}) => (
  <Box {...slotProps?.box}>
    <Alert
      severity={variant}
      variant="outlined"
      action={action}
      {...slotProps?.alert}>
      {headline && <AlertTitle>{headline}</AlertTitle>}
      {body && <Typography variant="bodySm">{body}</Typography>}
      {children && children}
    </Alert>
  </Box>
);

export const EmptyStateWithIllustration: FunctionComponentWithChildren = ({
  children
}) => (
  <CenteredBox
    maxWidth="sm"
    sx={{
      // Generated by Copilot
      minHeight: '50vh',
      justifyContent: 'center',
      alignSelf: 'center'
    }}>
    <Stack gap={1.25} alignItems="center">
      <img
        src="/no-dashboard-available.svg"
        alt="no dashboard is available."
        height={180}
      />
      <Typography variant="caption" textAlign="center">
        {children}
      </Typography>
    </Stack>
  </CenteredBox>
);
