import { GlobalKeysContext } from '@bonsai-components/react-global-keys';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Stack,
  SvgIcon
} from '@mui/material';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useRouter } from 'next/router';
import {
  FunctionComponent,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import {
  ACCOUNT_SETTINGS_PATH,
  EXPLORE_API_PATH,
  HOME_PAGE_PATH
} from '../../../constants/general';
import {
  ConfigurationContext,
  ModerneTenantConfiguration
} from '../../../contexts/config.context';
import { UserContext } from '../../../contexts/user.context';
import { determineHref } from '../../../helpers/link.helper';
import {
  excludeActionFromInputMode,
  useCommandPalette
} from '../../../hooks/use-command-palette.hooks';
import { useSelectedOrganization } from '../../../hooks/use-organizations.hooks';
import {
  GraphQLIcon,
  OrganizationIcon,
  SearchIcon
} from '../../../icons/icons';
import { useSelectedOrganizationStore } from '../../../stores/organization-store';
import { AccountMenu } from '../../account-menu/account-menu.component';
import { AutoCompleteSearch } from '../../global-search/global-search.component';
import { OrganizationsTable } from '../../organizations/organizations.table.component';
import { searchCommandShortcutSettings } from '../../settings/manage-appearance/keyboard-shortcuts-settings';
import { Link } from '../../utilities/moderne-link/moderne-link.component';
import { HelpMenu } from '../help-menu/help-menu.component';
import { AdminMenu } from '../left-nav/admin-menu-item.component';
import { LeftNavigationMenuItem } from '../left-nav/left-nav-menu-item.component';
import { LeftNavigationRail } from '../left-nav/left-navigation.styled';
import {
  MenuItemWithDialog,
  MenuItemWithSubMenu
} from '../left-nav/menu-with-sub-menu.component';
import { getLeftNavMenuItems } from '../left-nav/navigation-items';
import { NavigationRailMore } from './navigation-rail-more.component';

export const NavigationRail: FunctionComponent = () => {
  const { config } = useContext(ConfigurationContext);
  const { selectedOrganizationId } = useSelectedOrganizationStore();
  const leftNavMenuItems = getLeftNavMenuItems({ selectedOrganizationId });
  const { push } = useRouter();
  const navRef = useRef<HTMLDivElement>(null);

  const allItems = [
    () => (
      <ListItem
        sx={{
          // using margin to compensate for the lack of a text label on the
          // moderne icon
          marginBottom: 1
        }}>
        <ListItemButton href={HOME_PAGE_PATH} LinkComponent={Link}>
          <ListItemIcon>
            <img src="/moderne-icon.svg" alt="Moderne" height={24} />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    ),
    () => <SearchMenuItem />,
    () => <OrganizationSelectorMenuItem />,
    ...leftNavMenuItems.map((menuItem, idx) => (
      <LeftNavigationMenuItem key={`fixed-menu-${idx}`} menuItem={menuItem} />
    )),
    () => <AdminMenu />,
    () => (
      <LeftNavigationMenuItem
        key={`api-explorer`}
        menuItem={{
          icon: <SvgIcon inheritViewBox component={GraphQLIcon} />,
          href: EXPLORE_API_PATH,
          label: 'API explorer',
          description: 'Find and use GraphQL APIs'
        }}
      />
    ),
    () => <HelpMenu />
  ];

  const [visibleItems, setVisibleItems] = useState(allItems);
  const [overflowItems, setOverflowItems] = useState([]);

  useLayoutEffect(() => {
    const updateOverflowItems = () => {
      const nav = navRef.current;

      const elementHeight =
        (nav.children?.[1] as HTMLElement)?.offsetHeight + 8; // 8 px padding between items
      let totalHeight = elementHeight; // Start with the first element height (Moderne icon)
      const minElements = 1; // Starting index for allItems (e.g., Moderne icon)
      const navHeight = nav?.offsetHeight || 0;

      const newVisibleItems = [...allItems.slice(0, minElements)];
      const newOverflowItems = [];

      allItems.slice(minElements).forEach((item) => {
        totalHeight += elementHeight;

        if (navHeight - totalHeight < elementHeight) {
          newOverflowItems.push(item);
        } else {
          newVisibleItems.push(item);
        }
      });

      setOverflowItems(newOverflowItems);
      setVisibleItems(newVisibleItems);
    };
    updateOverflowItems(); // Initial check
    window.addEventListener('resize', updateOverflowItems);
    return () => {
      window.removeEventListener('resize', updateOverflowItems);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId]);

  useCommandPalette([
    {
      label: 'Go to Home',
      action: () => (e) =>
        excludeActionFromInputMode(e, () => push(HOME_PAGE_PATH)),
      keyboardShortcuts: {
        key: 'h'
      }
    },
    {
      label: 'Go to Account settings',
      action: () => push(ACCOUNT_SETTINGS_PATH),
      keyboardShortcuts: {
        key: '~',
        modifier: {
          shift: true
        }
      }
    },
    ...leftNavMenuItems.map((i) => ({
      label: `Go to ${i.label}`,
      description: i.description,
      action: (e) =>
        excludeActionFromInputMode(e, (_e) => push(determineHref(i.href))),
      keyboardShortcuts: i.shortcut
        ? {
            key: i.shortcut
          }
        : undefined
    }))
  ]);

  return (
    <LeftNavigationRail id="nav-destinations">
      <List
        ref={navRef}
        sx={{
          overflowY: 'hidden',
          overflowX: 'hidden',
          flexGrow: 1
        }}>
        {visibleItems.map((Item, idx) =>
          typeof Item === 'function' ? (
            <Item key={`visible-menu-${idx}`} />
          ) : (
            Item
          )
        )}
        {overflowItems?.length > 0 ? (
          <NavigationRailMore id="navigation-more">
            {overflowItems.map((Item, idx) =>
              typeof Item === 'function' ? (
                <Item key={`overflow-menu-${idx}`} />
              ) : (
                Item
              )
            )}
          </NavigationRailMore>
        ) : null}
      </List>
      <Stack id="nav-account" gap={1}>
        <CustomerBranding config={config} />
        <AccountMenu />
      </Stack>
    </LeftNavigationRail>
  );
};

type CustomerBrandingProps = {
  config?: ModerneTenantConfiguration;
};

export const CustomerBranding: FunctionComponent<CustomerBrandingProps> = ({
  config = {}
}) => {
  if (!config?.brandingIcon) {
    return null;
  }

  return (
    <Box
      sx={{
        border: `none`,
        borderRadius: '4px',
        boxSizing: 'border-box',
        alignSelf: 'center',
        overflow: 'hidden',
        display: 'inline-flex',
        height: 60,
        width: 60,
        maxWidth: 60,
        maxHeight: 60
      }}>
      <img
        src={config?.brandingIcon || config.brandingColor}
        alt={config.name}
        style={{
          display: 'block',
          alignSelf: 'center',
          height: 60,
          width: 60,
          maxWidth: 60,
          maxHeight: 60
        }}
      />
    </Box>
  );
};

const SearchMenuItem: FunctionComponent = () => {
  const popoverState = usePopupState({
    variant: 'popover',
    popupId: 'global-search'
  });

  const menuRef = useRef<HTMLDivElement>(null);

  const {
    preferences: { searchMenuShortcut }
  } = useContext(UserContext);

  const { addKeyBinding } = useContext(GlobalKeysContext);

  useEffect(() => {
    const shortcutSetting = searchCommandShortcutSettings[searchMenuShortcut];

    shortcutSetting?.bindings.forEach((binding) => {
      addKeyBinding({
        ...binding,
        action: (originalEvent) =>
          excludeActionFromInputMode(originalEvent, (event) => {
            originalEvent.preventDefault();
            originalEvent.stopPropagation();
            popoverState.setAnchorEl(menuRef.current);
            popoverState.open(event);
          })
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MenuItemWithSubMenu
      popoverState={popoverState}
      menuItem={{
        icon: <SearchIcon />,
        label: 'Search',
        description: 'Search for recipes'
      }}
      slotProps={{
        listItemButtonProps: {
          ref: menuRef
        },
        menuProps: {
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          slotProps: {
            paper: {
              sx: {
                boxShadow: (theme) => theme.shadows[10],
                maxWidth: { xs: '90vw', md: '70vw' }
              }
            }
          }
        }
      }}>
      <AutoCompleteSearch toggleMenu={popoverState.close} />
    </MenuItemWithSubMenu>
  );
};

const OrganizationSelectorMenuItem: FunctionComponent = () => {
  const { selectedOrganization } = useSelectedOrganization();

  const popoverState = usePopupState({
    variant: 'popover',
    popupId: 'organization-selector'
  });

  useCommandPalette([
    {
      label: 'Open organization selector',
      action: (e) => {
        e.stopPropagation();
        e.preventDefault();
        popoverState.open();
      },
      keyboardShortcuts: {
        key: 'o'
      }
    }
  ]);

  return (
    <MenuItemWithDialog
      popoverState={popoverState}
      menuItem={{
        icon: <OrganizationIcon />,
        label: 'Organization',
        description: selectedOrganization?.name ?? 'Select an organization'
      }}
      slotProps={{
        dialog: {
          fullWidth: true,
          maxWidth: 'lg'
        }
      }}>
      <OrganizationsTable onClose={popoverState.close} />
    </MenuItemWithDialog>
  );
};
