import { FunctionComponent, useContext, useState } from 'react';

import {
  Alert,
  AlertTitle,
  IconButton,
  Snackbar,
  Typography
} from '@mui/material';
import { useAgentVersionCheckQuery } from '../../../__generated__/apollo-hooks';
import { ConfigurationContext } from '../../../contexts/config.context';
import { CloseIcon, RestrictedIcon } from '../../../icons/icons';
import { useUserPreferenceStore } from '../../../stores/user-preference.store';

type AgentVersion = {
  major: number;
  minor: number;
  patch: number;
};

const convertVersionStringToVersion = (version: string): AgentVersion => {
  const [major, minor, patch] = version.split('.').map((v) => parseInt(v, 10));
  return { major, minor, patch };
};

const isTenantAgentOutdated = (
  tenantAgent: string,
  latestAgent: string
): boolean => {
  const latest = convertVersionStringToVersion(latestAgent);
  const tenant = convertVersionStringToVersion(tenantAgent);
  if (tenant.major < latest.major) {
    return true;
  }
  if (tenant.major === latest.major && tenant.minor < latest.minor) {
    return true;
  }
  if (
    tenant.major === latest.major &&
    tenant.minor === latest.minor &&
    tenant.patch < latest.patch
  ) {
    return true;
  }
  return false;
};

export const UpdateAgent: FunctionComponent = () => {
  const [open, setOpen] = useState<boolean>(true);

  const {
    config: { latestAgentVersion }
  } = useContext(ConfigurationContext);
  const { dismissedAgentUpdates = [], update } = useUserPreferenceStore();

  const { data } = useAgentVersionCheckQuery();

  const currentAgentVersion = data?.agents?.edges?.reduce((prev, current) => {
    return prev > current.node.version ? prev : current.node.version;
  }, '');

  const isNewerVersion = Boolean(
    latestAgentVersion &&
      currentAgentVersion &&
      isTenantAgentOutdated(currentAgentVersion, latestAgentVersion) &&
      !dismissedAgentUpdates?.includes(latestAgentVersion)
  );

  const handleAgentDismissal = () => {
    setOpen(false);
    dismissedAgentUpdates.push(latestAgentVersion);
    update({ dismissedAgentUpdates });
  };

  return isNewerVersion ? (
    <Snackbar
      open={open}
      autoHideDuration={120000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Alert
        icon={<RestrictedIcon fontSize="small" />}
        elevation={2}
        severity="info"
        action={
          <IconButton onClick={handleAgentDismissal} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        }>
        <AlertTitle>Moderne Agent update available</AlertTitle>
        <Typography variant="bodySm">
          We see you&apos;re currently running {currentAgentVersion} of the
          Moderne Agent.
        </Typography>
        <Typography variant="bodySm" paragraph>
          Version {latestAgentVersion} of Moderne Agent is now available.
        </Typography>
        <Typography
          variant="caption"
          sx={{
            color: 'indigo.400'
          }}>
          Note: This is only visible to administrators.
        </Typography>
      </Alert>
    </Snackbar>
  ) : null;
};
