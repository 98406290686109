import { Badge, Box, badgeClasses, styled } from '@mui/material';

export const ScmBadge = styled(Badge)(() => ({
  [`& .${badgeClasses.standard}`]: {
    padding: 0,
    minWidth: 12,
    height: 12
  }
}));

/**
 * Does not use the badge component but intended to be used in the same way but
 * as an inline component that simply displays its children as a badge.
 */
export const InlineBadge = styled(Box)(({ theme }) => ({
  borderRadius: 30,
  fontSize: 12,
  padding: `0 ${theme.spacing(1)}`,
  fontWeight: 500
}));
