import { RepositoryFragment } from '../__generated__/apollo-hooks';
import { WorkerRepository } from '../__generated__/apollo-hooks-worker';

export const repositoryFragmentToInput = (
  repository: Partial<RepositoryFragment> | Partial<WorkerRepository>
): { origin: string; path: string; branch: string } => ({
  branch: repository?.branch || '',
  origin: repository?.origin || '',
  path: repository?.path || ''
});
