import { Box, Typography } from '@mui/material';
import Image from 'next/image';
import React, { FunctionComponent } from 'react';
import {
  CORPORATE_SITE_URL,
  NEWSLETTER_SIGN_UP_URL
} from '../../../constants/general';
import { Link } from '../../utilities/moderne-link/moderne-link.component';

type SignInFooterProps = {
  isMultiTenant: boolean;
};

export const SignInFooter: FunctionComponent<SignInFooterProps> = ({
  isMultiTenant
}) => {
  const linkMessage = isMultiTenant
    ? 'Learn about enterprise options'
    : 'Learn more about Moderne';

  return (
    <React.Fragment>
      {isMultiTenant && (
        <Box
          sx={{
            marginTop: 2,
            width: '100%',
            display: 'flex',
            alignItems: 'center'
          }}>
          <Image
            src="/emojis/VictorySignEmoji.png"
            alt="Victory sign emoji"
            width={20}
            height={20}
          />
          <Typography
            variant="bodySm"
            align="left"
            sx={{
              marginLeft: 1,
              fontWeight: 300,
              color: (theme) => theme.palette.grey[200]
            }}>
            <b>Free</b> for open source projects
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          marginTop: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center'
        }}>
        <Image
          src="/emojis/ThoughtBubbleEmoji.png"
          alt="Thought bubble emoji"
          width={20}
          height={20}
        />
        <Typography
          variant="bodySm"
          align="left"
          sx={{
            marginLeft: 1,
            fontWeight: 300,
            color: (theme) => theme.palette.grey[200]
          }}>
          <Link
            color="grey.200"
            href={CORPORATE_SITE_URL}
            external
            underline="always">
            {linkMessage}
          </Link>
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center'
        }}>
        <Image
          src="/emojis/NewsPaperEmoji.png"
          alt="News paper emoji"
          width={20}
          height={20}
        />
        <Typography
          variant="bodySm"
          align="left"
          sx={{
            marginLeft: 1,
            fontWeight: 300,
            color: (theme) => theme.palette.grey[200]
          }}>
          {' '}
          <Link
            color="grey.200"
            href={NEWSLETTER_SIGN_UP_URL}
            external
            underline="always">
            Sign up for our newsletter
          </Link>
        </Typography>
      </Box>
    </React.Fragment>
  );
};
