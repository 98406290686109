import { ApolloError, ServerError, ServerParseError } from '@apollo/client';
import { FunctionComponentWithChildren } from '@bonsai-components/utility-types';
import {
  Alert,
  AlertTitle,
  Button,
  List,
  ListItem,
  Typography
} from '@mui/material';
import { logError } from '../../../helpers/logger.helper';
import { ReplayIcon } from '../../../icons/icons';

const isAServerOrServerParseError = (
  error: Error | ServerError | ServerParseError | null
): error is ServerError | ServerParseError => {
  return isAServerError(error) || isAServerParseError(error);
};
export const isAServerError = (
  error: Error | ServerError | ServerParseError | null
): error is ServerError => {
  return (error as ServerError)?.statusCode !== undefined;
};

const isAServerParseError = (
  error: Error | ServerError | ServerParseError | null
): error is ServerParseError => {
  return (error as ServerParseError)?.bodyText !== undefined;
};

const isNotFoundError = (error: ApolloError): boolean => {
  return (
    error?.message.toLowerCase().includes('not found') ||
    error?.message.toLowerCase().includes('not find') ||
    (isAServerOrServerParseError(error) && error.statusCode === 404)
  );
};

const isUnauthorizedError = (error: ApolloError): boolean => {
  return (
    error?.message.toLowerCase().includes('unauthorized') ||
    error?.message.toLowerCase().includes('access denied') ||
    (isAServerOrServerParseError(error) && error.statusCode === 401)
  );
};

const isUnavailableError = (error: ApolloError): boolean => {
  return (
    error?.message.toLowerCase().includes('econnrefused') ||
    (isAServerOrServerParseError(error) && error.statusCode === 500)
  );
};

const isDataIntegrityError = (error: ApolloError): boolean => {
  return error?.message.toLowerCase().includes('declared as a non null type');
};

const isAgentUnavailable = (error: ApolloError): boolean => {
  return error?.message.toLowerCase().includes('no matching agents');
};

const isInternalServerError = (error: ApolloError): boolean => {
  return error?.message.toLowerCase().includes('internal server error');
};
const isScmExchangeError = (error: ApolloError): boolean => {
  return error?.message.toLowerCase().includes('exchange');
};

const getFriendlyErrorMessage = (error: ApolloError): string => {
  if (isScmExchangeError(error)) {
    return error.message;
  } else if (isUnauthorizedError(error)) {
    return 'You are not authorized to perform this action.';
  } else if (isDataIntegrityError(error) && !isAgentUnavailable(error)) {
    return 'We encountered an issue with retrieving your request.';
  } else if (isUnavailableError(error)) {
    return 'Please wait while we attempt to reconnect.';
  } else if (isNotFoundError(error)) {
    return 'Unable to find the requested resource.';
  } else if (
    isInternalServerError(error) ||
    (isAServerOrServerParseError(error?.networkError) &&
      error.networkError?.statusCode === 400)
  ) {
    return 'Looks like we could not connect to our sources to find what you were looking for.';
  } else if (isAgentUnavailable(error)) {
    return 'We were unable to connect to or find an Agent required to display this content.';
  } else {
    return error?.message || 'An unknown error has occurred.';
  }
};

const getErrorTitle = (error: ApolloError): string => {
  if (isScmExchangeError(error)) {
    return 'Unable to authorize with your SCM provider.';
  } else if (isUnauthorizedError(error)) {
    return 'Unauthorized';
  } else if (isDataIntegrityError(error) && !isAgentUnavailable(error)) {
    return 'Unable to find the data you requested';
  } else if (isNotFoundError(error)) {
    return 'Not found';
  } else if (isUnavailableError(error)) {
    return 'Unavailable';
  } else if (
    isInternalServerError(error) ||
    isAServerOrServerParseError(error)
  ) {
    return 'Network error';
  } else if (isAgentUnavailable(error)) {
    return 'Trouble connecting to agent';
  } else {
    return 'GraphQL error';
  }
};

export const ModerneGraphQLError: FunctionComponentWithChildren<{
  error: ApolloError;
  refetch?: () => unknown;
}> = ({ error, children = null, refetch }) => {
  logError(error);
  return error ? (
    <Alert
      severity="error"
      action={
        refetch ? (
          <Button
            startIcon={<ReplayIcon />}
            variant="contained"
            size="small"
            onClick={() => refetch()}>
            Retry
          </Button>
        ) : undefined
      }>
      <AlertTitle>{getErrorTitle(error)}</AlertTitle>

      {error?.graphQLErrors?.length > 0 ? (
        <List>
          {error?.graphQLErrors.map((err, idx) => (
            <ListItem key={idx} variant="bodySm">
              {err.message}
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="bodySm">
          {children || getFriendlyErrorMessage(error)}
        </Typography>
      )}
    </Alert>
  ) : null;
};
