/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSvgIcon } from '@mui/material';
import AccessTokenIcon from '../public/moderne-icons/AccessTokenIcon.svg';
import AccessibilityIcon from '../public/moderne-icons/AccessibilityIcon.svg';
import AddColumnLeftIcon from '../public/moderne-icons/AddColumnLeftIcon.svg';
import AddColumnRightIcon from '../public/moderne-icons/AddColumnRightIcon.svg';
import AddNodeIcon from '../public/moderne-icons/AddNodeIcon.svg';
import AddToListIcon from '../public/moderne-icons/AddToListIcon.svg';
import AgentsIcon from '../public/moderne-icons/AgentsIcon.svg';
import AppleIcon from '../public/moderne-icons/AppleIcon.svg';
import ArrowCircleDown from '../public/moderne-icons/ArrowCircleDown.svg';
import ArrowCircleUp from '../public/moderne-icons/ArrowCircleUp.svg';
import AuditLogIcon from '../public/moderne-icons/AuditLogIcon.svg';
import BasicRecipeIcon from '../public/moderne-icons/BasicRecipeIcon.svg';
import BitbucketIcon from '../public/moderne-icons/BitbucketIcon.svg';
import BranchIcon from '../public/moderne-icons/BranchIcon.svg';
import BrowseRecipeIcon from '../public/moderne-icons/BrowseRecipeIcon.svg';
import BuildRecipeIcon from '../public/moderne-icons/BuildRecipeIcon.svg';
import BuilderIcon from '../public/moderne-icons/Builder.svg';
import CampaignSuccess from '../public/moderne-icons/CampaignSuccess.svg';
import CancelIcon from '../public/moderne-icons/CancelIcon.svg';
import CanceledIcon from '../public/moderne-icons/CanceledIcon.svg';
import CheckedIcon from '../public/moderne-icons/CheckedIcon.svg';
import CheckedOutlineIcon from '../public/moderne-icons/CheckedOutlineIcon.svg';
import CheveronLeftIcon from '../public/moderne-icons/CheveronLeftIcon.svg';
import CheveronRightIcon from '../public/moderne-icons/CheveronRightIcon.svg';
import CloseIcon from '../public/moderne-icons/CloseIcon.svg';
import CollapseAllIcon from '../public/moderne-icons/CollapseAllIcon.svg';
import CollapseDialogIcon from '../public/moderne-icons/CollapseDialogIcon.svg';
import CollapseIcon from '../public/moderne-icons/CollapseIcon.svg';
import ColumnsIcon from '../public/moderne-icons/ColumnsIcon.svg';
import CommandPalletIcon from '../public/moderne-icons/CommandPalletIcon.svg';
import ComposeRecipeIcon from '../public/moderne-icons/ComposeRecipeIcon.svg';
import ConfigureIcon from '../public/moderne-icons/ConfigureIcon.svg';
import CopyConfirmedIcon from '../public/moderne-icons/CopyConfirmedIcon.svg';
import CopyIcon from '../public/moderne-icons/CopyIcon.svg';
import CreateNewIcon from '../public/moderne-icons/CreateNewIcon.svg';
import CsvIcon from '../public/moderne-icons/CsvIcon.svg';
import DatatablesIcon from '../public/moderne-icons/DatatablesIcon.svg';
import DebugIcon from '../public/moderne-icons/DebugIcon.svg';
import DeleteIcon from '../public/moderne-icons/DeleteIcon.svg';
import DeployRecipesIcon from '../public/moderne-icons/DeployRecipesIcon.svg';
import DevCenterIcon from '../public/moderne-icons/DevCenterIcon.svg';
import DiffIcon from '../public/moderne-icons/DiffIcon.svg';
import DiscordIcon from '../public/moderne-icons/DiscordIcon.svg';
import DoubleArrowReset from '../public/moderne-icons/DoubleArrowReset.svg';
import DownloadIcon from '../public/moderne-icons/DownloadIcon.svg';
import DuplicateIcon from '../public/moderne-icons/DuplicateIcon.svg';
import EditIcon from '../public/moderne-icons/EditIcon.svg';
import EllipsisIcon from '../public/moderne-icons/EllipsisIcon.svg';
import EmailIcon from '../public/moderne-icons/EmailIcon.svg';
import ErrorIcon from '../public/moderne-icons/ErrorIcon.svg';
import ExcelIcon from '../public/moderne-icons/ExcelIcon.svg';
import ExpandAllIcon from '../public/moderne-icons/ExpandAllIcon.svg';
import ExpandIcon from '../public/moderne-icons/ExpandIcon.svg';
import ExportIcon from '../public/moderne-icons/ExportIcon.svg';
import ExternalLinkIcon from '../public/moderne-icons/ExternalLinkIcon.svg';
import FileAddedIcon from '../public/moderne-icons/FileAddedIcon.svg';
import FileModifiedIcon from '../public/moderne-icons/FileModifiedIcon.svg';
import FileRemovedIcon from '../public/moderne-icons/FileRemovedIcon.svg';
import FileRenamedIcon from '../public/moderne-icons/FileRenamedIcon.svg';
import FilterIcon from '../public/moderne-icons/FilterIcon.svg';
import FilterListIcon from '../public/moderne-icons/FilterListIcon.svg';
import ForkIcon from '../public/moderne-icons/ForkIcon.svg';
import GitCommitIcon from '../public/moderne-icons/GitCommitIcon.svg';
import GitHubIcon from '../public/moderne-icons/GitHubIcon.svg';
import GitLabIcon from '../public/moderne-icons/GitLabIcon.svg';
import GpgKeyIcon from '../public/moderne-icons/GpgKeyIcon.svg';
import GraphQLIcon from '../public/moderne-icons/GraphQLIcon.svg';
import HelpIcon from '../public/moderne-icons/HelpIcon.svg';
import HistoryIcon from '../public/moderne-icons/HistoryIcon.svg';
import ImportFromFileIcon from '../public/moderne-icons/ImportFromFileIcon.svg';
import InfoIcon from '../public/moderne-icons/InfoIcon.svg';
import JsonIcon from '../public/moderne-icons/JSONIcon.svg';
import LeftClickRotateIcon from '../public/moderne-icons/LeftClickRotateIcon.svg';
import LegendHideIcon from '../public/moderne-icons/LegendHideIcon.svg';
import LegendShowIcon from '../public/moderne-icons/LegendShowIcon.svg';
import LinkIcon from '../public/moderne-icons/LinkIcon.svg';
import LinuxIcon from '../public/moderne-icons/LinuxIcon.svg';
import LoadingIcon from '../public/moderne-icons/LoadingIcon.svg';
import MarketplaceIcon from '../public/moderne-icons/MarketplaceIcon.svg';
import MergeIcon from '../public/moderne-icons/MergeIcon.svg';
import MinusFilledIcon from '../public/moderne-icons/MinusFilledIcon.svg';
import MinusIcon from '../public/moderne-icons/MinusIcon.svg';
import MoreActionsIcon from '../public/moderne-icons/MoreActionsIcon.svg';
import OpenInFullIcon from '../public/moderne-icons/OpenInFullIcon.svg';
import OpenIssueIcon from '../public/moderne-icons/OpenIssueIcon.svg';
import OrganizationsIcon from '../public/moderne-icons/OrganizationsIcon.svg';
import PullRequestIcon from '../public/moderne-icons/PullRequestIcon.svg';
import QuarantinedIcon from '../public/moderne-icons/QuarantinedIcon.svg';
import QuarantinedRepoIcon from '../public/moderne-icons/QuarantinedRepoIcon.svg';
import QueuedIcon from '../public/moderne-icons/QueuedIcon.svg';
import RainyIcon from '../public/moderne-icons/RainyIcon.svg';
import RecenterIcon from '../public/moderne-icons/RecenterIcon.svg';
import RecipeInheritedFromIcon from '../public/moderne-icons/RecipeInheritedFromIcon.svg';
import RecommendedSortIcon from '../public/moderne-icons/RecommendedSortIcon.svg';
import ReloadIcon from '../public/moderne-icons/ReloadIcon.svg';
import RemoveAllIcon from '../public/moderne-icons/RemoveAllIcon.svg';
import ReorderIcon from '../public/moderne-icons/ReorderIcon.svg';
import ReplayIcon from '../public/moderne-icons/ReplayIcon.svg';
import RepoGroupIcon from '../public/moderne-icons/RepoGroupIcon.svg';
import RepositoryIcon from '../public/moderne-icons/RepositoryIcon.svg';
import ResetTreeIcon from '../public/moderne-icons/ResetTreeIcon.svg';
import RestrictedIcon from '../public/moderne-icons/RestrictedIcon.svg';
import ReverseTreeIcon from '../public/moderne-icons/ReverseTreeIcon.svg';
import RightArrowIcon from '../public/moderne-icons/RightArrowIcon.svg';
import RightClickPanIcon from '../public/moderne-icons/RightClickPanIcon.svg';
import RunIcon from '../public/moderne-icons/RunIcon.svg';
import SaveFloppyIcon from '../public/moderne-icons/SaveFloppyIcon.svg';
import SaveToBrowser from '../public/moderne-icons/SaveToBrowser.svg';
import ScrollWheelIcon from '../public/moderne-icons/ScrollWheelIcon.svg';
import SearchIcon from '../public/moderne-icons/SearchIcon.svg';
import SelectAllIcon from '../public/moderne-icons/SelectAllIcon.svg';
import SettingsIcon from '../public/moderne-icons/SettingsIcon.svg';
import ShareIcon from '../public/moderne-icons/ShareIcon.svg';
import SlackIcon from '../public/moderne-icons/SlackIcon.svg';
import SortAZAlphaIcon from '../public/moderne-icons/SortAZAlphaIcon.svg';
import SortZAAlphaIcon from '../public/moderne-icons/SortZAAlphaIcon.svg';
import StatusDownIcon from '../public/moderne-icons/StatusDownIcon.svg';
import StatusUpIcon from '../public/moderne-icons/StatusUpIcon.svg';
import SunnyIcon from '../public/moderne-icons/SunnyIcon.svg';
import SunnySpellsIcon from '../public/moderne-icons/SunnySpellsIcon.svg';
import TagIcon from '../public/moderne-icons/TagIcon.svg';
import TopicsIcon from '../public/moderne-icons/TopicsIcon.svg';
import UnavailableIcon from '../public/moderne-icons/UnavailableIcon.svg';
import UndoIcon from '../public/moderne-icons/UndoIcon.svg';
import UnlinkIcon from '../public/moderne-icons/UnlinkIcon.svg';
import UnquarentineIcon from '../public/moderne-icons/UnquarentineIcon.svg';
import UpArrowIcon from '../public/moderne-icons/UpArrowIcon.svg';
import UploadIcon from '../public/moderne-icons/UploadIcon.svg';
import ValidateBuildIcon from '../public/moderne-icons/ValidateBuildIcon.svg';
import VisualizationIcon from '../public/moderne-icons/VisualizationIcon.svg';
import WarningIcon from '../public/moderne-icons/WarningIcon.svg';
import WindowsIcon from '../public/moderne-icons/WindowsIcon.svg';
import WorkerIcon from '../public/moderne-icons/WorkerIcon.svg';
import ZipArchiveIcon from '../public/moderne-icons/ZipArchiveIcon.svg';
import ZoomInIcon from '../public/moderne-icons/ZoomInIcon.svg';
import ZoomOutIcon from '../public/moderne-icons/ZoomOutIcon.svg';

export const ModAccessTokenIcon = createSvgIcon(
  AccessTokenIcon(),
  'AccessTokenIcon'
);

const ModAccessibilityIcon = createSvgIcon(
  AccessibilityIcon(),
  'ModAccessibilityIcon'
);
const ModAddToListIcon = createSvgIcon(AddToListIcon(), 'ModAddToListIcon');
const ModAddNodeIcon = createSvgIcon(AddNodeIcon(), 'ModAddNodeIcon');
export const ModAgentsIcon = createSvgIcon(AgentsIcon(), 'ModAgentsIcon');
export const ModAppleIcon = createSvgIcon(AppleIcon(), 'ModAppleIcon');
export const ModArrowCircleDown = createSvgIcon(
  ArrowCircleDown(),
  'ModArrowCircleDown'
);
export const ModArrowCircleUp = createSvgIcon(
  ArrowCircleUp(),
  'ModArrowCircleUp'
);
export const ModAuditLogIcon = createSvgIcon(AuditLogIcon(), 'ModAuditLogIcon');
export const ModBasicRecipeIcon = createSvgIcon(
  BasicRecipeIcon(),
  'ModBasicRecipeIcon'
);
export const ModBitbucketIcon = createSvgIcon(
  BitbucketIcon(),
  'ModBitbucketIcon'
);
export const ModBranchIcon = createSvgIcon(BranchIcon(), 'ModBranchIcon');
const ModBrowseRecipeIcon = createSvgIcon(
  BrowseRecipeIcon(),
  'ModBrowseRecipeIcon'
);
const ModBuildRecipeIcon = createSvgIcon(
  BuildRecipeIcon(),
  'ModBuildRecipeIcon'
);
export const ModBuilderIcon = createSvgIcon(BuilderIcon(), 'ModBuilderIcon');
export const ModCancelIcon = createSvgIcon(CancelIcon(), 'ModCancelIcon');
export const ModCanceledIcon = createSvgIcon(CanceledIcon(), 'ModCanceledIcon');
const ModCampaignSuccessIcon = createSvgIcon(
  CampaignSuccess(),
  'ModCampaignSuccessIcon'
);
const ModCheckedIcon = createSvgIcon(CheckedIcon(), 'ModCheckedIcon');
export const ModCheckedOutlineIcon = createSvgIcon(
  CheckedOutlineIcon(),
  'ModCheckedOutlineIcon'
);
const ModCheveronLeftIcon = createSvgIcon(
  CheveronLeftIcon(),
  'ModCheveronLeftIcon'
);
const ModCheveronRightIcon = createSvgIcon(
  CheveronRightIcon(),
  'ModCheveronRightIcon'
);
const ModCloseIcon = createSvgIcon(CloseIcon(), 'ModCloseIcon');
export const ModCollapseIcon = createSvgIcon(CollapseIcon(), 'ModCollapseIcon');
export const ModColumnsIcon = createSvgIcon(ColumnsIcon(), 'ModColumnsIcon');
export const ModCollapseAllIcon = createSvgIcon(
  CollapseAllIcon(),
  'ModCollapseAllIcon'
);
const ModCollapseDialogIcon = createSvgIcon(
  CollapseDialogIcon(),
  'ModCollapseDialogIcon'
);
export const ModCommandPalletIcon = createSvgIcon(
  CommandPalletIcon(),
  'ModCommandPalletIcon'
);
export const ModComposeRecipeIcon = createSvgIcon(
  ComposeRecipeIcon(),
  'ModComposeRecipeIcon'
);
const ModConfigureIcon = createSvgIcon(ConfigureIcon(), 'ModConfigureIcon');
const ModCopyConfirmedIcon = createSvgIcon(
  CopyConfirmedIcon(),
  'ModCopyConfirmedIcon'
);
const ModCopyIcon = createSvgIcon(CopyIcon(), 'ModCopyIcon');
export const ModCsvIcon = createSvgIcon(CsvIcon(), 'ModCsvIcon');
export const ModDatatablesIcon = createSvgIcon(
  DatatablesIcon(),
  'ModDatatablesIcon'
);
const ModDebugIcon = createSvgIcon(DebugIcon(), 'ModDebugIcon');
export const ModDeleteIcon = createSvgIcon(DeleteIcon(), 'ModDeleteIcon');
export const ModDeployRecipesIcon = createSvgIcon(
  DeployRecipesIcon(),
  'ModDeployRecipesIcon'
);
export const ModDevCenterIcon = createSvgIcon(DevCenterIcon(), 'DevCenterIcon');
const ModDiffIcon = createSvgIcon(DiffIcon(), 'ModDiffIcon');
export const ModDiscordIcon = createSvgIcon(DiscordIcon(), 'ModDiscordIcon');
export const ModDoubleArrowIcon = createSvgIcon(
  DoubleArrowReset(),
  'ModDoubleArrowIcon'
);
const ModDownloadIcon = createSvgIcon(DownloadIcon(), 'ModDownloadIcon');
const ModDuplicateIcon = createSvgIcon(DuplicateIcon(), 'ModDuplicateIcon');
export const ModErrorIcon = createSvgIcon(ErrorIcon(), 'ModErrorIcon');
export const ModExcelIcon = createSvgIcon(ExcelIcon(), 'ModExcelIcon');
export const ModJsonIcon = createSvgIcon(JsonIcon(), 'ModJsonIcon');
export const ModExpandAllIcon = createSvgIcon(
  ExpandAllIcon(),
  'ModExpandAllIcon'
);
export const ModExpandIcon = createSvgIcon(ExpandIcon(), 'ModExpandIcon');
export const ModExportIcon = createSvgIcon(ExportIcon(), 'ModExportIcon');
export const ModExternalLinkIcon = createSvgIcon(
  ExternalLinkIcon(),
  'ModExternalLinkIcon'
);

export const ModFileAddedIcon = createSvgIcon(
  FileAddedIcon(),
  'ModFileAddedIcon'
);
export const ModFileModifiedIcon = createSvgIcon(
  FileModifiedIcon(),
  'ModFileModifiedIcon'
);
export const ModFileRemovedIcon = createSvgIcon(
  FileRemovedIcon(),
  'ModFileRemovedIcon'
);
export const ModFileRenamedIcon = createSvgIcon(
  FileRenamedIcon(),
  'ModFileRenamedIcon'
);
const ModFilterIcon = createSvgIcon(FilterIcon(), 'ModFilterIcon');
export const ModFilterListIcon = createSvgIcon(
  FilterListIcon(),
  'ModFilterListIcon'
);
const ModForkIcon = createSvgIcon(ForkIcon(), 'ModForkIcon');
const ModGitCommitIcon = createSvgIcon(GitCommitIcon(), 'ModGitCommitIcon');
export const ModGitHubIcon = createSvgIcon(GitHubIcon(), 'ModGitHubIcon');
export const ModGitLabIcon = createSvgIcon(GitLabIcon(), 'ModGitLabIcon');
const ModGpgKeyIcon = createSvgIcon(GpgKeyIcon(), 'ModGpgKeyIcon');
export const ModGraphQLIcon = createSvgIcon(GraphQLIcon(), 'ModGraphQLIcon');
export const ModHelpIcon = createSvgIcon(HelpIcon(), 'ModHelpIcon');
export const ModHistoryIcon = createSvgIcon(HistoryIcon(), 'ModHistoryIcon');
export const ModEmailIcon = createSvgIcon(EmailIcon(), 'ModEmailIcon');
const ModImportFromFileIcon = createSvgIcon(
  ImportFromFileIcon(),
  'ModImportFromFileIcon'
);
export const ModInfoIcon = createSvgIcon(InfoIcon(), 'ModInfoIcon');
export const ModLegendHideIcon = createSvgIcon(
  LegendHideIcon(),
  'ModLegendHideIcon'
);
export const ModLegendShowIcon = createSvgIcon(
  LegendShowIcon(),
  'ModLegendShowIcon'
);
export const ModLinuxIcon = createSvgIcon(LinuxIcon(), 'ModLinuxIcon');
const ModLoadingIcon = createSvgIcon(LoadingIcon(), 'ModLoadingIcon');
export const ModMarketplaceIcon = createSvgIcon(
  MarketplaceIcon(),
  'ModMarketplaceIcon'
);
export const ModMinusIcon = createSvgIcon(MinusIcon(), 'ModMinusIcon');
export const ModMinusFilledIcon = createSvgIcon(
  MinusFilledIcon(),
  'ModMinusFilledIcon'
);
const ModMergeIcon = createSvgIcon(MergeIcon(), 'ModMergeIcon');
const ModMoreActionsIcon = createSvgIcon(
  MoreActionsIcon(),
  'ModMoreActionsIcon'
);
export const ModEditIcon = createSvgIcon(EditIcon(), 'ModEditIcon');
export const ModEllipsisIcon = createSvgIcon(EllipsisIcon(), 'ModEllipsisIcon');
const ModOpenInFullIcon = createSvgIcon(OpenInFullIcon(), 'ModOpenInFullIcon');
const ModOpenIssueIcon = createSvgIcon(OpenIssueIcon(), 'ModOpenIssueIcon');
export const ModOrganizationsIcon = createSvgIcon(
  OrganizationsIcon(),
  'ModOrganizationsIcon'
);
const ModPullRequestIcon = createSvgIcon(
  PullRequestIcon(),
  'ModPullRequestIcon'
);
export const ModQuarantinedIcon = createSvgIcon(
  QuarantinedIcon(),
  'ModQuarantinedIcon'
);
const ModQuarantinedRepoIcon = createSvgIcon(
  QuarantinedRepoIcon(),
  'ModQuarantinedRepoIcon'
);
const ModQueuedIcon = createSvgIcon(QueuedIcon(), 'ModQueuedIcon');
export const ModRainyIcon = createSvgIcon(RainyIcon(), 'ModRainyIcon');
const ModRecenterIcon = createSvgIcon(RecenterIcon(), 'ModRecenterIcon');
const ModRecipeInheritedFromIcon = createSvgIcon(
  RecipeInheritedFromIcon(),
  'ModRecipeInheritedFromIcon'
);
export const ModRecommendedSortIcon = createSvgIcon(
  RecommendedSortIcon(),
  'ModRecommendedSortIcon'
);
export const ModReloadIcon = createSvgIcon(ReloadIcon(), 'ModReloadIcon');
const ModRemoveAllIcon = createSvgIcon(RemoveAllIcon(), 'ModRemoveAllIcon');
const ModReorderIcon = createSvgIcon(ReorderIcon(), 'ModReorderIcon');
const ModReplayIcon = createSvgIcon(ReplayIcon(), 'ModReplayIcon');
const ModRepoGroupIcon = createSvgIcon(RepoGroupIcon(), 'ModRepoGroupIcon');
export const ModRepositoryIcon = createSvgIcon(
  RepositoryIcon(),
  'ModRepositoryIcon'
);
const ModResetTreeIcon = createSvgIcon(ResetTreeIcon(), 'ModResetTreeIcon');
const ModRestrictedIcon = createSvgIcon(RestrictedIcon(), 'ModRestrictedIcon');
const ModReverseTreeIcon = createSvgIcon(
  ReverseTreeIcon(),
  'ModReverseTreeIcon'
);
const ModRightArrowIcon = createSvgIcon(RightArrowIcon(), 'ModRightArrowIcon');
const ModRunIcon = createSvgIcon(RunIcon(), 'ModRunIcon');
export const ModSaveFloppyIcon = createSvgIcon(
  SaveFloppyIcon(),
  'ModSaveFloppyIcon'
);
export const ModSaveToBrowser = createSvgIcon(
  SaveToBrowser(),
  'ModSaveToBrowser'
);
export const ModSearchIcon = createSvgIcon(SearchIcon(), 'ModSearchIcon');
const ModSelectAllIcon = createSvgIcon(SelectAllIcon(), 'ModSelectAllIcon');
export const ModSettingsIcon = createSvgIcon(SettingsIcon(), 'ModSettingsIcon');
export const ModShareIcon = createSvgIcon(ShareIcon(), 'ModShareIcon');
export const ModSlackIcon = createSvgIcon(SlackIcon(), 'ModSlackIcon');
export const ModSortAZAlphaIcon = createSvgIcon(
  SortAZAlphaIcon(),
  'ModSortAZAlphaIcon'
);
export const ModSortZAAlphaIcon = createSvgIcon(
  SortZAAlphaIcon(),
  'ModSortZAAlphaIcon'
);
const ModStatusDownIcon = createSvgIcon(StatusDownIcon(), 'ModStatusDownIcon');
const ModStatusUpIcon = createSvgIcon(StatusUpIcon(), 'ModStatusUpIcon');
export const ModSunnyIcon = createSvgIcon(SunnyIcon(), 'ModSunnyIcon');
export const ModSunnySpellsIcon = createSvgIcon(
  SunnySpellsIcon(),
  'ModSunnySpellsIcon'
);
const ModTopicsIcon = createSvgIcon(TopicsIcon(), 'ModTopicsIcon');
const ModUnavailableIcon = createSvgIcon(
  UnavailableIcon(),
  'ModUnavailableIcon'
);
const ModUnquarentineIcon = createSvgIcon(
  UnquarentineIcon(),
  'ModUnquarentineIcon'
);
const ModUpArrowIcon = createSvgIcon(UpArrowIcon(), 'ModUpArrowIcon');
export const ModUploadIcon = createSvgIcon(UploadIcon(), 'ModUploadIcon');
const ModValidateBuildIcon = createSvgIcon(
  ValidateBuildIcon(),
  'ModValidateBuildIcon'
);
const ModVisualizationIcon = createSvgIcon(
  VisualizationIcon(),
  'ModVisualizationIcon'
);
export const ModWarningIcon = createSvgIcon(WarningIcon(), 'ModWarningIcon');
export const ModWindowsIcon = createSvgIcon(WindowsIcon(), 'ModWindowsIcon');
export const ModWorkerIcon = createSvgIcon(WorkerIcon(), 'ModWorkerIcon');
const ModZipArchiveIcon = createSvgIcon(ZipArchiveIcon(), 'ModZipArchiveIcon');
const ModZoomInIcon = createSvgIcon(ZoomInIcon(), 'ModZoomInIcon');
const ModZoomOutIcon = createSvgIcon(ZoomOutIcon(), 'ModZoomOutIcon');

export const ModCreateNewIcon = createSvgIcon(
  CreateNewIcon(),
  'ModCreateNewIcon'
);

export const ModTagIcon = createSvgIcon(TagIcon(), 'ModTagIcon');

export const ModScrollWheelIcon = createSvgIcon(
  ScrollWheelIcon(),
  'ModScrollWheelIcon'
);
export const ModRightClickPanIcon = createSvgIcon(
  RightClickPanIcon(),
  'ModScrollWheelIcon'
);
export const ModLeftClickRotateIcon = createSvgIcon(
  LeftClickRotateIcon(),
  'ModScrollWheelIcon'
);

export const ModUndoIcon = createSvgIcon(UndoIcon(), 'ModUndoIcon');

export const ModAddColumnLeftIcon = createSvgIcon(
  AddColumnLeftIcon(),
  'AddColumnLeft'
);
export const ModAddColumnRightIcon = createSvgIcon(
  AddColumnRightIcon(),
  'AddColumnRight'
);

export const ModLinkIcon = createSvgIcon(LinkIcon(), 'LinkIcon');
export const ModUnlinkIcon = createSvgIcon(UnlinkIcon(), 'UnlinkIcon');
